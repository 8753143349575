import { NavLink } from 'react-router-dom';

const logo = require('../assets/images/logo.png');

const Sidebar = () => {

    return (
        <div className="sidebar">
            <div className='logo'>
                <NavLink to={'/admin/home'}>
                <img src={logo} />
                </NavLink>
            </div>
            <div className="accordion" id="adminSidebar">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            User
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#adminSidebar">
                        <div className="accordion-body">
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                <li className="nav-item">
                                    <NavLink to={'newUser'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                        Admin
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'existingUser'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Workshop</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'plans'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Employee</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Customers
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#adminSidebar">
                        <div className="accordion-body">
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                <li className="nav-item">
                                    <NavLink to={'newUser'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                        Create
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'existingUser'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Customers</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Car Types
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#adminSidebar">
                        <div className="accordion-body">
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                <li className="nav-item">
                                    <NavLink to={'/admin/carTypeForm'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                        Create
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'/admin/carTypes'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Types</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Services
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#adminSidebar">
                        <div className="accordion-body">
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                <li className="nav-item">
                                    <NavLink to={'/admin/serviceForm'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                        Create
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'/admin/services'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>List</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            Plans
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#adminSidebar">
                        <div className="accordion-body">
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                <li className="nav-item">
                                    <NavLink to={'/admin/planForm'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                        Create
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'/admin/plans'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>List</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            Package
                        </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#adminSidebar">
                        <div className="accordion-body">
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                <li className="nav-item">
                                    <NavLink to={'/admin/packageForm'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                        Create
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'/admin/packages'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>List</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            Reports
                        </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#adminSidebar">
                        <div className="accordion-body">
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                <li className="nav-item">
                                    <NavLink to={'existingUser'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>List</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Sidebar;
