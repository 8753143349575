import { Error, Success, Warning } from "../assets/svg/Icons";
import { Constant } from "../constants";
import Button from "./Button";

const InfoModal = ({
    type = '',
    title = '',
    msg = '',
    icon = false,
    closeBtn = true,
    closeBtnText = 'Close',
    continueBtn = false,
    continueBtnText = '',
    dismissBtn = false,
    action }) => {
    return (
        <>
            <div className="modal fade show d-block" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0">
                            <h1 className="modal-title fs-5 text-center" id="staticBackdropLabel">{title}</h1>
                            {
                                dismissBtn &&
                                <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close" onClick={() => action(Constant.cancel)}></button>
                            }
                        </div>
                        <div className="modal-body text-center">
                            {
                                icon &&
                                < div style={styles.icon}>
                                    {type === Constant.success && <Success />}
                                    {type === Constant.error && <Error />}
                                    {type === Constant.warning && <Warning />}
                                </div>
                            }

                            <p className="mt-3">
                                {msg}
                            </p>
                        </div>
                        <div className="modal-footer justify-content-center">
                            {closeBtn &&
                                <Button title={closeBtnText} className={'btn btn-secondary'} action={() => action(Constant.close)} />
                            }
                            {
                                continueBtn &&
                                <Button title={continueBtnText} className={'btn btn-p'} action={() => action(Constant.proceed)} />
                            }
                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            {/* <button type="button" className="btn btn-primary">Understood</button> */}
                        </div>
                    </div>
                </div>
            </div >
            <div className="modal-backdrop fade show"></div>
        </>
    )
}

export default InfoModal;

const styles = {
    icon: { height: '64px', width: '64px', margin: '0 auto' }
}