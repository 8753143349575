import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import './style.css';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { getExtras, removeExtra } from '../../../store/features/extrasSlice';
import { Chip } from '../../../components/Chip';
import { claerBilling, getBilling, updateBilling, updateGSTPrice } from '../../../store/features/billingSlice';
import { getSelectedPlans, updateExtraServices, updateExtras, removeExtra, updateService, updateServicePrice, updateExtraName, updateExtraPrice, getCustomerDetails, getSelectedType, clearPlanAndServices, updateServicePriceTotal, updateServiceQty, updateExtraPriceTotal, updateExtrasQty } from '../../../store/features/planSlice';
import { Label } from '../../../components/Label';
import { Input } from '../../../components/Input';
import { useHTTP } from '../../../hooks/useHTTP';
import Loader from '../../../components/Loader';
import { Error } from '../../../components/Error';
import { Constant } from '../../../constants';
import { Select } from '../../../components/Select';
import { numberRegex, priceRegex } from '../../../utils/regex';
import InfoModal from '../../../components/InfoModal';
import InvoicePDF from './InvoicePDF';

const Employer = [{ 'name': 'workshop', 'uuid': '1af3dd3e-6c0b-4ebb-aabc-fd695a8bd64a' }];

function Billing() {
    const { loading: isGeneratingBill, callAPI: createBill, data: responseForCreate, error: errorForCreate, success: createdBill } = useHTTP();
    const { promocode, washType, washPrice, extraPrice, dirtyWork, dirtyNotes, discount, gst, total, subTotal, gstPrice } = useSelector(getBilling);
    const { carType: selectedCarType, plan: planFromStore, services: servicesFromStore, extras: extrasFromStore } = useSelector(getSelectedPlans);
    const { loading: isSendingInvoice, callAPI: sendInvoice, data: responseForInvoice, error: errorForInvoice, success: sentInvoice } = useHTTP();
    const selectedType = useSelector(getSelectedType);
    const customerDetails = useSelector(getCustomerDetails);
    const [planPrice, setPlanPrice] = useState();
    const [modalInfo, setModalInfo] = useState({});
    const [notes, setNotes] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Selected Car price
    useEffect(() => {
        if (Object.keys(planFromStore).length) {
            const findPlanPrice = planFromStore?.prices?.find((pr, index) => customerDetails?.car_type?.uuid === pr?.uuid);
            setPlanPrice(findPlanPrice);
        }
    }, [selectedCarType, planFromStore]);

    // GST Price updates
    useEffect(() => {
        if (subTotal) {
            const payload = ((subTotal - Number(discount)) * gst) / 100;
            dispatch(updateBilling({ name: 'gstPrice', value: payload }));
        }
    }, [subTotal, discount, gst]);

    // Total price update
    useEffect(() => {
        if (subTotal) {
            const payload = (subTotal + gstPrice) - Number(discount);
            dispatch(updateBilling({ name: 'total', value: payload }));
        }
    }, [subTotal, gstPrice, discount, gst])

    // Update sub total
    useEffect(() => {
        let serviceSubTotal = 0;
        let extraSubTotal = 0;
        let pp = planPrice?.price || 0;
        if (servicesFromStore.length) {
            serviceSubTotal = servicesFromStore.reduce((acc, obj) => obj?.servicePriceTotal ? Number(acc) + Number(obj?.servicePriceTotal) : acc, 0);
        }
        if (extrasFromStore?.length) {
            extraSubTotal = extrasFromStore.reduce((acc, obj) => obj?.totalPrice ? Number(acc) + Number(obj?.totalPrice) : acc, 0);
        }
        const st = Number(pp) + Number(serviceSubTotal) + Number(extraSubTotal);
        const payload = { name: 'subTotal', value: st };
        dispatch(updateBilling(payload));
    }, [planPrice, servicesFromStore, extrasFromStore, discount]);

    useEffect(() => {
        const postInvoice = async (responseForCreate) => {
            const blob = await InvoicePDF(responseForCreate?.data);
            console.log('InvoicePDF', blob)
            const formData = new FormData();
            formData.append('invoice_id', responseForCreate?.data?.uuid);
            formData.append('invoice', blob, 'invoice.pdf');
            sendInvoice('invoice/file', Constant.POST, formData);
        }

        if (responseForCreate.status === Constant.success) {
            postInvoice(responseForCreate)
        }
        if (errorForCreate !== null) {
        }
    }, [responseForCreate, errorForCreate]);

    useEffect(() => {
        if (responseForInvoice?.status === Constant.success) {
            navigate('/workshop/invoice', { state: { invoiceData: JSON.stringify(responseForCreate?.data) } });
        }
        if (errorForInvoice !== null) {
            console.log('errorForInvoice', errorForInvoice)
        }
    }, [responseForInvoice, errorForInvoice, responseForCreate])

    const handleNotes = (e) => {
        const { value } = e.target;
        setNotes(value);
    }

    // Add Extras
    const addExtraServices = () => {
        const payload = { id: extrasFromStore?.length, name: '', price: '' };
        dispatch(updateExtras(payload))
    }

    // Remove added services
    const removeServices = (ser) => {
        dispatch(updateService(ser));
    }

    // Remove extras service
    const removeExtraService = (ext) => {
        dispatch(removeExtra(ext));
    }

    // Extras name service update
    const handleExtraName = useCallback((e) => {
        const { id, value } = e?.target;
        const payload = { id: Number(id), 'name': value };
        dispatch(updateExtraName(payload));
    }, []);

    // Extras Price update
    const handleExtraPrice = useCallback((e, ext) => {
        const { id, value } = e?.target;
        if (!priceRegex.test(value) && value !== '') return;
        const payload = { id: Number(id), 'price': value };
        dispatch(updateExtraPrice(payload));

        const qtyValue = ext?.qty || 1;
        const payloadForTotal = { id: Number(id), 'totalPrice': value * qtyValue };
        dispatch(updateExtraPriceTotal(payloadForTotal));
    }, []);

    // Qty update for extras
    const handleExtraQtyChange = useCallback((e, ext) => {
        const { id, value } = e?.target;
        if (!numberRegex.test(value) && value !== '') return;
        const payload = { id: Number(id), 'qty': value };
        dispatch(updateExtrasQty(payload));

        const totalQtyPrice = (value || 1) * ext?.price;
        const payloadForTotal = { id: Number(id), 'totalPrice': totalQtyPrice };
        dispatch(updateExtraPriceTotal(payloadForTotal));

    }, []);

    // Service price update
    const handlePrice = useCallback((e, ser) => {
        const { value } = e?.target;
        if (!priceRegex.test(value) && value !== '') return;
        const payload = { ...ser, 'servicePrice': value };

        const qtyValue = ser?.qty === null ? 1 : Number(ser?.qty);
        const payloadForTotal = { ...ser, 'servicePriceTotal': value * qtyValue };

        dispatch(updateServicePrice(payload));
        dispatch(updateServicePriceTotal(payloadForTotal));

    }, []);

    // qty update
    const handleQtyChange = useCallback((e, ser) => {
        const { value } = e?.target;
        if (!numberRegex.test(value) && value !== '') return;
        const serviceTotalQtyPrice = (value || 1) * ser?.servicePrice;
        const payload = { ...ser, 'qty': value };
        const payloadForTotal = { ...ser, 'servicePriceTotal': serviceTotalQtyPrice };
        dispatch(updateServiceQty(payload));
        dispatch(updateServicePriceTotal(payloadForTotal));
    }, []);

    const handleDiscount = useCallback((e) => {
        const { id, name, value } = e?.target;
        if (!priceRegex.test(value) && value !== '') return;
        dispatch(updateBilling({ 'name': 'discount', value }))
    }, []);

    const handlePromocode = useCallback((e) => {
        const { id, name, value } = e?.target;
        dispatch(updateBilling({ 'name': id, value }))
    }, []);

    const applyPromocode = useCallback(() => {
        console.log('Promocode API call');
    }, []);

    const goBack = useCallback(() => {
        let msg = { ...modalInfo };
        msg = { show: true, type: Constant.warning, msg: 'Are you sure you want to Exit?' };
        setModalInfo(msg);
        // navigate(-1);
    }, []);

    // Navigate to Invoice 
    const generateBill = useCallback(() => {
        // navigate('/workshop/invoice');
        const serviceErrors = servicesFromStore?.find((ser, i) => !ser?.hasOwnProperty('servicePrice') || ser?.servicePrice === "");
        const extraErrors = extrasFromStore?.find((ext, i) => (ext?.price === "" || ext?.name === ""));

        if (discount > subTotal) {
            alert('Please check doscount price')
            return;
        }
        if (serviceErrors === undefined && extraErrors === undefined) {
            console.log('ok. Billing Api process');
            billingProcess();
        }
    }, [servicesFromStore, extrasFromStore, subTotal, discount, gstPrice, total, notes]);

    const billingProcess = async () => {
        const servicePayload = servicesFromStore?.map(ser => { return { 'service': ser?.uuid, 'price': ser?.servicePrice, 'qty': Number(ser?.qty) || 1 } })
        const extraPayload = extrasFromStore?.map(ext => { return { 'other': ext?.name, 'price': ext?.price, 'qty': Number(ext?.qty) || 1 } });
        const nameForKey = selectedType === 'plan' ? 'plan' : 'package';
        if (!servicePayload.length && !extraPayload.length && selectedType === '') {
            alert('Not able to Generate Bill!!')
            return;
        }
        const typePayload = { [nameForKey]: planFromStore?.uuid, 'price': planPrice?.price, 'qty': 1 };
        const payload = {
            customer: customerDetails?.uuid,
            invoice_date: new Date().toLocaleDateString('en-CA'),
            due_date: new Date().toLocaleDateString('en-CA'),
            terms: '',
            sub_total: subTotal,
            gst: gstPrice,
            total: total,
            discount: Number(discount),
            promos_id: '',
            balance_due: '',
            employer: Employer[0]?.uuid,
            notes: notes,
            services: servicePayload,
            plans: selectedType === 'plan' ? [typePayload] : [],
            packages: selectedType === 'package' ? [typePayload] : [],
            others: extraPayload
        }
        console.log('payload', payload);

        createBill('invoice/create', Constant.POST, payload);
    }

    // Modal 
    const modalAction = useCallback((type) => {
        setModalInfo({})
        if (type === Constant.close) {
            return;
        }

        if (type === Constant.proceed) {
            dispatch(clearPlanAndServices());
            dispatch(claerBilling());
            navigate('/workshop/plans');
            return;
        }
    }, []);

    if (isGeneratingBill || isSendingInvoice) {
        return <Loader />
    }
    return (
        <div className='page billing'>
            <div className='card-container mx-4'>
                <div className='row'>
                    <div className='col-sm-12 col-md-4 align-self-center'>
                        <div className='billing-address'>
                            <h5 className='mb-4'>Billing Details</h5>
                            <Label title={customerDetails?.name} />
                            <Label title={customerDetails?.address} />
                            <Label title={customerDetails?.phone} />
                            <Label title={customerDetails?.email} />

                            <div className='my-4'>
                                <label className='poppins-light'>Rego Number:</label>
                                <Label title={customerDetails?.regno} />
                            </div>

                            <div className='my-4'>
                                <label className='poppins-light'>Car Type:</label>
                                <Label title={customerDetails?.car_type?.name} />
                            </div>

                            <div className='my-4 w-50'>
                                <label className='poppins-light'>Employer</label>
                                <Select value={Employer[0]?.uuid} options={Employer} onChange={() => console.log('change employer')} />
                            </div>
                            <div className='my-4 w-75'>
                                <Label title={'Notes'} />
                                <textarea name='notes' id='notes' className='form-control' onChange={handleNotes}>{notes}</textarea>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12 col-md-8'>
                        <table className='table'>
                            <thead className='heading-wrap'>
                                <th>Services</th>
                                <th>Rate</th>
                                <th className='text-center'>Qty</th>
                                <th className='text-end'>Price</th>
                                <th className='text-end'>Add/Remove</th>
                            </thead>
                            <tbody>
                                {
                                    (!planFromStore?.name && !servicesFromStore.length && !extrasFromStore.length) &&
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><Label title={'Add Extras'} /></td>
                                        <td className='text-end'>
                                            <Button className={'ms-2 btn-p'} title={'+'} action={() => addExtraServices()} />
                                        </td>
                                    </tr>
                                }
                                {
                                    planFromStore?.name &&
                                    <tr>
                                        <td>{planFromStore?.name}</td>
                                        <td>{planPrice?.price}</td>
                                        <td className='text-center'>1</td>
                                        <td className='text-end'> {Number(planPrice?.price).toFixed(2)}</td>
                                        <td className='text-end'>
                                            {
                                                (!servicesFromStore.length && !extrasFromStore.length) &&
                                                <Button className={'ms-2 btn-p'} title={'+'} action={() => addExtraServices()} />
                                            }
                                        </td>
                                    </tr>
                                }
                                {
                                    servicesFromStore?.map((ser, index) => {
                                        const { uuid, name = '', price = '', servicePrice = '', qty = 1, servicePriceTotal = '' } = ser;
                                        let qtyValue = qty === null ? 1 : qty;
                                        return (
                                            <tr key={index}>
                                                <td>{`${name} ${price ? `($${price})` : ''}`}</td>
                                                <td>
                                                    <Input placeholder='Price' classname={`f-12 ${servicePrice === '' && 'error'}`} value={servicePrice} maxLength={8} onChange={(e) => handlePrice(e, ser)} />
                                                </td>
                                                <td>
                                                    <Input placeholder='Qty' classname={`f-12 text-center ${qty === '' && 'error'}`} value={qtyValue} maxLength={3} onChange={(e) => handleQtyChange(e, ser)} />
                                                </td>
                                                <td className='text-end'>{servicePriceTotal ? `${Number(servicePriceTotal).toFixed(2)}` : ''}</td>
                                                <td className='text-end'>
                                                    <Button className={'btn-secondary'} title={'-'} action={() => removeServices(ser)} />
                                                    {
                                                        (servicesFromStore.length - 1 === index && !extrasFromStore.length) &&
                                                        <Button className={'ms-2 btn-p'} title={'+'} action={() => addExtraServices()} />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                {
                                    extrasFromStore?.map((ext, index) => {
                                        const { id, name = '', price = '', qty = 1, totalPrice = '' } = ext;
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Input placeholder='Enter Service Name' code={index} value={name} classname={`f-12 ${name === '' && 'error'}`} maxLength={250} onChange={handleExtraName} />
                                                </td>
                                                <td>
                                                    <Input placeholder='Price' code={index} classname={`f-12 ${price === '' && 'error'}`} value={price} maxLength={8} onChange={(e) => handleExtraPrice(e, ext)} />
                                                </td>
                                                <td>
                                                    <Input placeholder='Qty' code={index} classname={`f-12 text-center ${qty === '' && 'error'}`} value={qty} maxLength={5} onChange={(e) => handleExtraQtyChange(e, ext)} />
                                                </td>
                                                <td className='text-end'> {totalPrice ? `${Number(totalPrice).toFixed(2)}` : ''}</td>
                                                <td className='text-end'>
                                                    <Button className={'btn-secondary'} title={'-'} action={() => removeExtraService(ext)} />
                                                    {
                                                        extrasFromStore.length - 1 === index &&
                                                        <Button className={'ms-2 btn-p'} title={'+'} action={() => addExtraServices()} />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                <tr className='heading-wrap'>
                                    <td><Label title={'Sub Total'} /></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'><Label title={`$ ${subTotal.toFixed(2)}`} /></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td><Label title={'Discount'} /></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'>
                                        <Input placeholder='' code={'discount'} classname='f-12 text-end' value={discount} maxLength={8} onChange={handleDiscount} />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><Label title={`GST (${gst}%)`} /></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'><Label title={`$ ${gstPrice.toFixed(2)}`} /></td>
                                    <td></td>
                                </tr>
                                <tr className='heading-wrap'>
                                    <td><Label title={'Total'} /></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'><Label title={`$ ${total.toFixed(2)}`} /></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td className='py-4'>Promocode: </td>
                                    <td className='w-50'>
                                        <Input placeholder='Enter Your Promocode ' code={'promocode'} classname='f-12 w-100' value={promocode} disabled onChange={handlePromocode} />
                                    </td>
                                    <td>
                                        <Button title={'Apply'} className='btn-primary disabled' action={() => applyPromocode()} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='mt-3 d-flex justify-content-end'>
                            <Button title={'Clear'} className='btn-secondary mx-4' action={goBack} />
                            <Button title={'Generate Bill'} className='btn-p' action={generateBill} />
                        </div>
                    </div>
                </div>
            </div>

            {
                modalInfo?.show &&
                <InfoModal continueBtnText='Continue' {...modalInfo} icon={true} continueBtn={true} action={(type) => modalAction(type)} />
            }
        </div>
    );
}

export default Billing; 