import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { TitleContainer } from "../../../components/TitleContainer"
import { useForm } from "../../../hooks/useForm";
import { useHTTP } from "../../../hooks/useHTTP";
import { Input } from "../../../components/Input";
import { Error } from "../../../components/Error";
import { packageForm } from "../../../assets/json/forms";
import { Label } from "../../../components/Label";
import { Checkbox } from "../../../components/Checkbox";
import Button from "../../../components/Button";
import InfoModal from "../../../components/InfoModal";
import Loader from "../../../components/Loader";
import { Constant } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Select } from "../../../components/Select";
import { Dropdown } from "../../../components/Dropdown";
import { MultiSelect } from "../../../components/MultiSelect";

const initCarTypeForm = {
    "car_type": '',
    "price": ''

};

const PackageForm = () => {
    const { formControls, setFormControls, onChange, onSubmit, errors, reset } = useForm(packageForm);
    const { loading: fetchingCarTypes, callAPI: getCarTypes, data: carTypesData = [], error: carTypesError } = useHTTP();
    const { loading: fetchingServices, callAPI: getServices, data: services, error: servicesError } = useHTTP();
    const { loading: fetchingPlans, callAPI: getPlans, data: plans, error: errorForplans } = useHTTP();
    const { loading: isCreating, callAPI: createAPI, data: responseForCreate, error: errorForCreate } = useHTTP();
    const [modalInfo, setModalInfo] = useState({});
    const { state: updatePackage } = useLocation();
    const editPackage = useMemo(() => updatePackage !== null ? JSON.parse(updatePackage) : null, [updatePackage]);
    const btnName = editPackage === null ? 'Create' : 'Update';
    const [addFormControl, setAddFormControl] = useState([initCarTypeForm]);
    const [servicesData, setServicesData] = useState([]);
    const [plansData, setplansData] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const navigate = useNavigate();

    // initial form
    useEffect(() => {
        if (editPackage === null) {
            reset();
            setSelectedServices([]);
            setSelectedPlans([]);
            setAddFormControl([initCarTypeForm]);
        } else {
            const cpyFormControls = [...formControls];
            for (const key in editPackage) {
                cpyFormControls.find(fc => {
                    if (fc.code === key) {
                        fc.value = editPackage[key];
                    }
                })
            }
            setFormControls(cpyFormControls);
        }
    }, [editPackage]);

    // Edit form
    useEffect(() => {
        if (editPackage !== null && editPackage?.services) {
            const transformData = editPackage?.services?.map(data => { return { 'value': data.uuid, 'label': data.name } });
            setSelectedServices(transformData);
        }

        if (editPackage !== null && editPackage?.plans) {
            const transformData = editPackage?.plans?.map(data => { return { 'value': data.uuid, 'label': data.name } });
            setSelectedPlans(transformData);
        }

        if (editPackage !== null && editPackage?.prices) {
            const priceData = editPackage?.prices?.map(data => { return { 'car_type': data.uuid, 'price': data.price } });
            setAddFormControl(priceData);
        }

    }, [editPackage])

    // Fetching Car Types
    useEffect(() => {
        getCarTypes('car-types', Constant.GET);
        getServices('services', Constant.GET);
        getPlans('plans', Constant.GET);
    }, []);

    // Transform servicess data
    useEffect(() => {
        if (services?.status === Constant?.success) {
            const transFormData = services?.data?.map(data => { return { 'value': data.uuid, 'label': data.name } })
            setServicesData(transFormData);
        }
    }, [services])

    // Transform Plans data
    useEffect(() => {
        if (plans?.status === Constant?.success) {
            const transFormData = plans?.data?.map(data => { return { 'value': data.uuid, 'label': data.name } })
            setplansData(transFormData);
        }
    }, [plans])

    // For Modal Message
    useEffect(() => {
        if (responseForCreate?.status === Constant.success) {
            let msg = { ...modalInfo };
            if (responseForCreate?.status === Constant.success) {
                msg = { show: true, type: Constant.success, msg: responseForCreate?.message };
            }
            setModalInfo(msg);
            setAddFormControl([initCarTypeForm]);
            setSelectedServices([]);
            setSelectedPlans([]);
            reset();
        }

        if (errorForCreate !== null) {
            reset();
        }
    }, [errorForCreate, responseForCreate]);

    // Input change
    const handleInput = useCallback((e) => {
        const { id, value } = e?.target;
        onChange(value, id);
    }, [formControls]);

    // Car type on select dynamic add
    const handleCarType = (e, index) => {
        const { value } = e?.target;
        const valueUpdate = addFormControl?.map((fc, i) => i === index ? { ...fc, car_type: value } : fc);
        setAddFormControl(valueUpdate)
    }

    // Price change
    const handlePrice = (e, index) => {
        const { value } = e?.target;
        const valueUpdate = addFormControl?.map((fc, i) => i === index ? { ...fc, price: value } : fc);
        setAddFormControl(valueUpdate)
    }

    // Checkbox status
    const handelCheckbox = (e) => {
        const { id, value } = e?.target;
        const checked = value === 'true' ? false : true;
        onChange(checked, id);
    }

    // On select service multiple
    const onSelectServices = (val, code) => {
        setSelectedServices(val);
        // onChange(val, code);
    }

    const onSelectPlans = (val, code) => {
        setSelectedPlans(val);
    }

    // create Package
    const handleCreate = useCallback(() => {
        if (onSubmit()) {
            const carServices = selectedServices?.map(s => s?.value);
            const carPlans = selectedPlans?.map(s => s?.value);

            const payload = {
                "name": formControls[0]?.value,
                "services": carServices?.toString(),
                "plans": carPlans?.toString(),
                "status": formControls[3]?.value,
                "prices": addFormControl
            }

            if (editPackage === null) {
                createAPI('packages/create', Constant.POST, payload);
            }
            if (editPackage !== null) {
                const id = editPackage?.uuid;
                createAPI(`packages/update/${id}`, Constant.POST, payload);
            }
        }
    }, [formControls, selectedServices, addFormControl]);

    // Modal 
    const modalAction = useCallback((type) => {
        if (type === Constant.close) {
            setModalInfo({})
        }
        if(editPackage !== null) {
            navigate('/admin/packages');
        }
    }, []);

    // Add +
    const addMoreCarType = () => {
        setAddFormControl([...addFormControl, initCarTypeForm]);
    }

    // Remove -
    const removeCarType = (i) => {
        const updateFormControls = addFormControl.filter((_, index) => index !== i);
        setAddFormControl(updateFormControls);
    }

    if (fetchingCarTypes || isCreating || fetchingServices || fetchingPlans) {
        return <Loader />
    }

    return (
        <div className="">
            <TitleContainer title='Create Package' />
            <div className="m-auto w-50">
                <div className="card-container w-100 my-4">
                    <div className="row">
                        <div className="col-12 mb-3 ">
                            <Label title={formControls[0]?.name} />
                            <Input {...formControls[0]} onChange={handleInput} />
                            <Error title={errors[0]} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3 ">
                            <Label title={formControls[1]?.name} />
                            <MultiSelect options={servicesData} defaultValue={selectedServices || null} onChange={(val) => onSelectServices(val, formControls[1]?.code)} />
                            {/* <Error title={!selectedServices.length  ? 'Services Required' : ''} /> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3 ">
                            <Label title={formControls[2]?.name} />
                            <MultiSelect options={plansData} defaultValue={selectedPlans || null} onChange={(val) => onSelectPlans(val, formControls[2]?.code)} />
                            {/* <Error title={!selectedServices.length  ? 'Services Required' : ''} /> */}
                        </div>
                    </div>

                    {
                        addFormControl?.map((ct, index) => {
                            return (
                                <div className="row" key={index}>
                                    <div className="col-6 mb-3" >
                                        <Label title={'Car Type'} />
                                        <Select value={ct?.car_type} options={carTypesData?.data} onChange={(e) => handleCarType(e, index)} />
                                        <Error title={errors[2]} />
                                    </div>

                                    <div className="col-4 mb-3">
                                        <Label title={'Price'} />
                                        <Input value={ct?.price} type="text" onChange={(e) => handlePrice(e, index)} />
                                        <Error title={errors[3]} />
                                    </div>
                                    <div className="col-2 mb-3">
                                        {
                                            addFormControl.length - 1 === index ?
                                                <Button className={'btn-p mt-3'} title={'+'} action={() => addMoreCarType()} />
                                                :
                                                <Button className={'btn-secondary mt-3'} title={'-'} action={() => removeCarType(index)} />
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="row">
                        <div className="col-12 mb-3 ">
                            <Label title={formControls[3]?.name} />
                            <input {...formControls[3]} id={formControls[3]?.code} className="form-check-input pt-3" checked={formControls[3]?.value} onChange={handelCheckbox} />
                        </div>
                    </div>

                    <div className='mt-3'>
                        <Button title={btnName} className='btn-p' action={handleCreate} />
                    </div>
                </div>
            </div>
            {
                modalInfo?.show &&
                <InfoModal {...modalInfo} icon={true} action={(type) => modalAction(type)} />
            }
        </div>
    )
}

export default PackageForm;