import React, { Fragment, useCallback, useEffect, useState } from 'react';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { useHTTP } from '../../../hooks/useHTTP';
import Button from '../../../components/Button';
import { Select } from '../../../components/Select';
import Loader from '../../../components/Loader';
import { Label } from '../../../components/Label';
import PackageCard from './PackageCard';
import { Input } from '../../../components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDetails, getSelectedPlans, updateCarType, updatePlan, updateProceedFrom, updateSelectedType, updateService } from '../../../store/features/planSlice';
import InfoModal from '../../../components/InfoModal';
import { Constant } from '../../../constants';

const Tabs = ['Packages', 'Plans', 'Services'];
const extraIco = require('../../../assets/images/extra.png');

function Plans() {
    const { loading: fetchingCarTypes, callAPI: getCarTypes, data: carTypes, error: fetchCarTypeError, success: fetchedCarType } = useHTTP();
    const { loading: fetchingPackages, callAPI: getPackages, data: packages, error: fetchPackagesError, success: fetchedPackages } = useHTTP();
    const { loading: fetchingPlans, callAPI: getPlans, data: plans, error: fetchplansError, success: fetchedPlans } = useHTTP();
    const { loading: fetchingServices, callAPI: getServices, data: services, error: fetchServicesError, success: fetchedServices } = useHTTP();
    const [selectedTab, setSelectedTab] = useState(Tabs[0]);
    const [selectedCarType, setSelectedCarType] = useState();
    const [packageLists, setPackageLists] = useState([]);
    const [plansLists, setPlansLists] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [serviceInput, setServiceInput] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { plan: selectedPlan, services: servicesFromStore } = useSelector(getSelectedPlans);
    const [modalInfo, setModalInfo] = useState({});
    const customerDetails = useSelector(getCustomerDetails);

    // get packages, car types
    useEffect(() => {
        getCarTypes('car-types');
    }, []);

    useEffect(() => {
        if (fetchedCarType) {
            getPackages('packages');
        }
    }, [fetchedCarType, fetchCarTypeError])

    // init car type
    useEffect(() => {
        if (fetchedCarType) {
            setSelectedCarType(carTypes?.data[0]?.uuid);
            dispatch(updateCarType(carTypes?.data[0]));
        }
    }, [fetchedCarType]);

    // init packages
    useEffect(() => {
        if (selectedCarType !== undefined) {
            const pcData = [];
            packages?.data?.filter(pc => pc?.prices?.filter(pr => {
                if (pr?.uuid === selectedCarType) {
                    pcData.push(pc);
                }
            }));
            setPackageLists(pcData);
        }
    }, [selectedCarType, fetchedPackages]);

    // init plans
    useEffect(() => {
        if (selectedCarType !== undefined) {
            const plData = [];
            plans?.data?.filter(pl => pl?.prices?.filter(pr => {
                if (pr?.uuid === selectedCarType) {
                    plData.push(pl);
                }
            }));
            setPlansLists(plData);
        }
    }, [selectedCarType, fetchedPlans]);

    useEffect(() => {
        if (fetchedServices) {
            const data = services?.data?.filter(ser =>
                ser?.uuid && !selectedPlan?.services?.some(sser => sser?.uuid === ser?.uuid)
            );
            setServicesList(data);
        }
    }, [selectedTab, fetchedServices]);

    // search searvice
    useEffect(() => {
        const filterServices = services?.data?.filter((ser, index) => ser?.name.toLowerCase().includes(serviceInput.toLowerCase()));
        setServicesList(filterServices);
    }, [serviceInput])

    // on select plan
    const onSelectPlan = (type, data) => {
        dispatch(updatePlan(data));
        dispatch(updateSelectedType(type));
        setSelectedTab(Tabs[Tabs.length - 1]);
        onClickTab(Tabs[Tabs.length - 1]);
    }

    const onClickTab = useCallback((activeTab) => {
        setSelectedTab(activeTab);
        if (activeTab === Tabs[0]) {
            getPackages('packages')
        }
        if (activeTab === Tabs[1]) {
            getPlans('plans');
        }
        if (activeTab === Tabs[2]) {
            getServices('services');
        }
    }, [selectedTab]);

    const onSelectService = (ser) => {
        dispatch(updateService(ser));
    }

    // change car type
    const onChangeCarType = (e) => {
        const { name, id, value } = e?.target;
        setSelectedCarType(value);
        const findCarType = carTypes?.data?.find(ct => ct?.uuid === value);
        dispatch(updateCarType(findCarType));
    }

    const onSearchInput = useCallback((e) => {
        const { name, id, value } = e?.target;

        setServiceInput(value);
    }, []);

    const navigateBilling = () => {
        if (customerDetails === null || customerDetails === undefined) {
            let msg = { ...modalInfo };
            msg = { show: true, msg: "Please choose before billing", dismissBtn: true };
            setModalInfo(msg);
        }
        if (customerDetails !== null && customerDetails !== undefined) {
            navigate('/workshop/billing')
        }
    }

    const modalAction = useCallback((type) => {
        setModalInfo({});
        if (type === Constant?.cancel) {
            dispatch(updateProceedFrom(false));
            return;
        }

        dispatch(updateProceedFrom(true));

        if (type === Constant.close) { // For now new user
            navigate('/workshop/newUser');
        }

        if (type === Constant.proceed) { // For now search user
            navigate('/workshop/existingUser');
        }
    }, []);

    if (fetchingCarTypes || fetchingPackages || fetchingPlans || fetchingServices) {
        return <Loader />
    }
    return (
        <div className='page'>
            <div className='price-page w-100'>
                <div className='d-flex justify-content-between mb-3'>
                    <div className=''>
                        <div className='d-inline-block w-auto me-3'>
                            <Select classname='' options={carTypes?.data} value={selectedCarType} onChange={onChangeCarType} />
                        </div>
                        {
                            Tabs?.map((btn, i) => {
                                return (
                                    <Fragment key={i}>
                                        <Button title={btn} className={`me-3 ${btn === selectedTab ? 'btn-p' : 'btn-secondary'}`} action={() => onClickTab(btn)} />
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                    <div className='text-end'>
                        {
                            selectedTab === Tabs[Tabs.length - 1] &&
                            <Button className={'btn-primary w-auto d-inline-block me-3'} title={'Proceed to Billing'} action={() => navigateBilling()} />
                        }

                    </div>
                </div>

                {
                    selectedTab === Tabs[0] &&
                    <div className='row'>
                        {
                            (fetchedPackages && !packageLists.length) ?
                                <Label title={'Package Not Available'} />
                                :
                                packageLists?.map((item, index) => {
                                    return (
                                        <div key={index} className='col-12 col-sm-6 col-lg-4 plan-card' onClick={() => onSelectPlan('package', item)}>
                                            <PackageCard plan={item} selectedCarType={selectedCarType} />
                                        </div>
                                    )
                                })
                        }
                    </div>
                }

                {
                    selectedTab === Tabs[1] &&
                    <div className='row'>
                        {
                            (fetchedPlans && !plansLists.length) ?
                                <Label title={'Plans Not Available'} />
                                :
                                plansLists?.map((item, index) => {
                                    return (
                                        <div key={index} className='col-12 col-sm-6 col-lg-4 plan-card' onClick={() => onSelectPlan('plan', item)}>
                                            <PackageCard plan={item} selectedCarType={selectedCarType} />
                                        </div>
                                    )
                                })
                        }
                    </div>
                }

                {
                    selectedTab === Tabs[2] &&
                    <div className='row'>
                        <div className=''>
                            <Input placeholder='Search service' value={serviceInput} onChange={onSearchInput} />
                        </div>
                        {
                            (fetchedServices && !services?.data?.length) ?
                                <Label title={'Plans Not Available'} />
                                :
                                servicesList?.map((ser, index) => {
                                    const activeService = servicesFromStore.find(s => s.uuid === ser.uuid);
                                    return (
                                        <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 my-2 ">
                                            <button className={`service-card ${activeService && 'active'}`} onClick={() => onSelectService(ser)}>
                                                <div>
                                                    <Label title={ser?.name} />
                                                    {
                                                        ser?.price &&
                                                        <h5 className="f-14 m-0 mt-1"><i>$ {ser?.price} </i><sup>*</sup></h5>
                                                    }
                                                </div>
                                                {
                                                    (!ser?.price || ser?.price === '0' || ser?.price === null) &&
                                                    <img src={extraIco} style={{ height: '24px', width: '24px' }} />
                                                }
                                            </button>
                                        </div>
                                    )
                                })
                        }
                    </div>
                }


            </div>
            {
                modalInfo?.show &&
                <InfoModal {...modalInfo} closeBtnText={'New User'} continueBtn={true} continueBtnText='Existing User' action={(type) => modalAction(type)} />
            }
        </div>
    );
}

export default Plans; 