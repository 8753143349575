// Admin.js
import React from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import Home from '../pages/admin/Home';
import Sidebar from '../components/Sidebar';
import CarTypeForm from '../pages/admin/carTypes/CarTypeForm';
import CarTypes from '../pages/admin/carTypes/CarTypes';
import ServiceForm from '../pages/admin/services/ServiceForm';
import Services from '../pages/admin/services/Services';
import PlanForm from '../pages/admin/plans/PlanForm';
import Plans from '../pages/admin/plans/Plans';
import PackageForm from '../pages/admin/package/PackageForm';
import Packages from '../pages/admin/package/Packages';

function AdminRoutes() {
    return (
        <div>
            <div className='sidebar-container'>
                <Sidebar />
            </div>
            <div className='admin-container'>
                <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/carTypeForm" element={<CarTypeForm />} />
                    <Route path="/carTypes" element={<CarTypes />} />
                    <Route path="/serviceForm" element={<ServiceForm />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/planForm" element={<PlanForm />} />
                    <Route path="/plans" element={<Plans />} />
                    <Route path="/packageForm" element={<PackageForm />} />
                    <Route path="/packages" element={<Packages/>} />
                </Routes>
            </div>
        </div>
    );
}

export default AdminRoutes;
