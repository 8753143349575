export const Input = ({
    type = 'text',
    name = '',
    code = '',
    value = '',
    placeholder = '',
    classname = '',
    maxLength = 36,
    disabled = false,
    onChange
}) => {
    return (
        <div className='form-group'>
            <input
                type={type}
                name={name}
                id={code}
                className={`form-control ${classname}`}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                disabled={disabled}
                onChange={onChange} />
        </div>
    )
}