import { Outlet, Navigate, useNavigate, useNavigation } from 'react-router-dom'
import { Constant } from '../constants';
import { getAuth } from '../store/features/authSlice';
import { useSelector } from 'react-redux';

const ProtectedRouteAdmin = () => {
    const { authendicated, role} = useSelector(getAuth);
    return (
        (authendicated && role === Constant.admin) ?  <Outlet /> :
        <Navigate to="/" />
    )
}

export default ProtectedRouteAdmin;