export const Checkbox = ({label = '', label2 = '', checked = false, onChange}) => {
    const id = label.replace(/\s/g, '');
    return (
        <div className="form-check w-100">
            <input className="form-check-input pt-3" type="checkbox"  id={id} checked={checked} onChange={onChange}/>
                <label className="form-check-label d-block" htmlFor={id}>
                    {label}
                </label>
                <label className="form-check-label d-block text-secondary f-14 pb-3" htmlFor={id}>
                     <i>from ${label2} </i>
                </label>
        </div>
    )
}