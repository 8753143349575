import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/login/Login";
import AdminRoutes from "./AdminRoutes";
import WorkshopRoutes from "./WorkshopRoutes";
import ProtectedRouteAdmin from "./ProtectedRouteAdmin";
import ProtectedRouteWorkshop from "./ProtectedRouteWorkshop";
import { AuthProvider } from "../contexts/Auth";

function AppRoutes() {
  return (
    <BrowserRouter>
      <AuthProvider>
      <Routes>
        <Route element={<ProtectedRouteAdmin />}>
          <Route element={<AdminRoutes />} path="/admin/*" />
        </Route>

        <Route element={<ProtectedRouteWorkshop />}>
          <Route element={<WorkshopRoutes />} path="/workshop/*" />
        </Route>

        <Route path="/" element={<Login />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default AppRoutes;
