import { useCallback, useEffect, useState } from "react";
import { TitleContainer } from "../../../components/TitleContainer"
import { useHTTP } from "../../../hooks/useHTTP";
import { Constant } from "../../../constants";
import Loader from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { Delete, Edit } from "../../../assets/svg/Icons";
import Button from "../../../components/Button";
import InfoModal from "../../../components/InfoModal";
import { Label } from "../../../components/Label";
import './styles.css';

const Plans = () => {
    const { loading: fetching, callAPI: getPlans, data: plansData, error: errorForFetching } = useHTTP();
    const { loading: deleting, callAPI: deletePlan, data: deleteResponse, error: errorForDelete } = useHTTP();
    const [modalInfo, setModalInfo] = useState({});
    const [selectedType, setSelectedType] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getPlans('plans', Constant.GET);
    }, []);

    useEffect(() => {
        if (deleteResponse?.status === Constant.success) {
            const options = {
                show: true,
                type: Constant.success,
                msg: deleteResponse?.message,
            };
            setModalInfo({ ...options });
            getPlans('plans', Constant.GET);
        }
    }, [deleteResponse]);

    const createNew = () => {
        navigate('/admin/planForm')
    }

    const onClickDeletePlan = (id) => {
        const options = {
            show: true,
            type: Constant.error,
            msg: 'Are you sure to delete this item?',
            continueBtn: true,
            continueBtnText: 'Yes'
        };
        setModalInfo({ ...options });
        setSelectedType(id);
    }

    // Modal 
    const modalAction = useCallback((type) => {
        setModalInfo({});
        // if (type === Constant.close) {
        //     setModalInfo({})
        // }
        if (type === Constant.proceed) {
            deletePlan(`plans/delete/${selectedType}`, Constant.DELETE)
        }
    }, [selectedType]);

    if (fetching || deleting) {
        return <Loader />
    }
    return (
        <div className="plan-page">
            <TitleContainer title='Plans' />
            <div className="w-100 ">
                <div className="text-end my-4">
                    <Button title={'Create New'} className={'btn-p'} action={() => createNew()} />
                </div>
                <div className="accordion" id="adminplans">

                    {
                        plansData?.data?.map((item, index) => {
                            const { name, prices, services, uuid } = item;
                            return (
                                <div key={index} className="my-4">
                                    <div key={index} className="accordion-item">
                                        <h2 className="accordion-header">
                                            <div className="d-flex">
                                                <div className="w-100">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#plans_${index}`} aria-expanded="true" aria-controls={`plans_${index}`}>
                                                        {name}
                                                    </button>
                                                </div>
                                                <div className="action-btn">
                                                    <Link className='mx-3' to={{ pathname: '/admin/planForm' }} state={JSON.stringify(item)}>
                                                        <Edit />
                                                    </Link>
                                                    <button className="btn" type="button" onClick={() => onClickDeletePlan(uuid)}>
                                                        <Delete />
                                                    </button>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id={`plans_${index}`} className="accordion-collapse collapse" >
                                            <div className="accordion-body">
                                                <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                                    {
                                                        services?.map((ser, index) => {
                                                            const { name } = ser;
                                                            return (
                                                                <li key={index} className="nav-item">
                                                                    <span className='text-black'>&#10003;</span> {name}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>

                                                <ul className="navbar-nav my-2 border-top mt-4 py-4">
                                                    {
                                                        prices?.map((pr, index) => {
                                                            const { name, price } = pr;
                                                            return (
                                                                <li key={index} className="nav-item">
                                                                    {name} - $ {price}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            {
                modalInfo?.show &&
                <InfoModal {...modalInfo} icon={true} action={(type) => modalAction(type)} />
            }
        </div>
    )
}

export default Plans;