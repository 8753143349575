import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './style.css';
import Button from '../../../components/Button';
import { Link, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { numberRegex } from '../../../utils/regex';
import { useHTTP } from '../../../hooks/useHTTP';
import { Constant } from '../../../constants';
import InfoModal from '../../../components/InfoModal';
import Loader from '../../../components/Loader';
import { useForm } from '../../../hooks/useForm';
import { customerForm } from '../../../assets/json/forms';
import { Input } from '../../../components/Input';
import { Error } from '../../../components/Error';
import { Label } from '../../../components/Label';
import { Select } from '../../../components/Select';
import { useDispatch, useSelector } from 'react-redux';
import { clearPlanAndServices, getProceed, updateCustomerDetails, updateProceedFrom } from '../../../store/features/planSlice';

function UserForm() {
    const { formControls, setFormControls, onChange, onSubmit, errors, reset } = useForm(customerForm);
    const { loading: fetchingCarTypes, callAPI: getCarTypes, data: carTypesData = [], error: carTypesError } = useHTTP();
    const { loading: creatingCustomer, callAPI: createCustomer, data: responseForCreate, error: errorForCreate } = useHTTP();
    const [modalInfo, setModalInfo] = useState({});
    const navigate = useNavigate();
    const location = useLocation()
    const { state: updateCustomer } = useLocation();
    const editCustomer = useMemo(() => updateCustomer !== null ? JSON.parse(updateCustomer) : null, [location]);
    const dispatch = useDispatch();
    const fromServicePage = useSelector(getProceed);

    useEffect(() => {
        if (editCustomer === null) {
            reset();
        } else {
            const cpyFormControls = [...formControls];
            for (const key in editCustomer) {
                cpyFormControls.find(fc => {
                    if (fc.code === key) {
                        fc.value = editCustomer[key];
                    }
                    if (fc.type === 'list' && fc.code === key) {
                        fc.value = editCustomer[key]?.uuid;
                    }
                })
            }
            setFormControls(cpyFormControls);
        }
    }, [editCustomer]);


    // Fetching Car Types
    useEffect(() => {
        getCarTypes('car-types', Constant.GET);
    }, []);

    // For Modal Message
    useEffect(() => {
        if (responseForCreate?.status === Constant.success) {
            let msg = { ...modalInfo };
            if (responseForCreate?.status === Constant.success) {
                msg = { show: true, type: Constant.success, msg: responseForCreate?.message, continueBtn: true };
            }
            if (fromServicePage) {
                msg = { show: true, type: Constant.success, msg: responseForCreate?.message, continueBtn: true, continueBtnText: 'Ok', closeBtn: false };
            }
            dispatch(updateCustomerDetails(responseForCreate?.data));
            setModalInfo(msg);
            reset();
        }

        if (errorForCreate !== null) {
            // reset();
            if (errorForCreate?.message?.phone) {
                let msg = { ...modalInfo };
                msg = { show: true, type: Constant.error, msg: errorForCreate?.message?.phone[0], continueBtn: false };
                setModalInfo(msg);
            }
        }
    }, [errorForCreate, responseForCreate, fromServicePage]);

    // Clear Plan / Package / Services
    useEffect(() => {
        if (fromServicePage) return;

        dispatch(clearPlanAndServices());
    }, [fromServicePage])

    // Input change
    const handleInput = useCallback((e) => {
        const { name, id, value } = e?.target;
        if (id === 'abn_number') {
            if (!numberRegex.test(value) && value !== '') {
                return false;
            }
        }
        onChange(value, id);
    }, [formControls]);

    // Submit form 
    const handleRegister = useCallback(() => {
        if (onSubmit()) {
            let payload = formControls.reduce((acc, currentElement) => {
                acc[currentElement.code] = currentElement.value;
                return acc;
            }, {});
            if (editCustomer === null) {
                createCustomer('customers/create', Constant.POST, payload);
            }
            if (editCustomer !== null) {
                createCustomer(`customers/update/${editCustomer?.uuid}`, Constant.POST, payload)
            }
        }
    }, [formControls]);

    // Reset Form values
    const resetForm = () => {
        reset();
    };

    const cancelAction = () => {
        navigate('/workshop/existingUser');
    }

    // Modal 
    const modalAction = useCallback((type) => {
        if (type === Constant.close) {
            setModalInfo({})
        }

        if (type === Constant.proceed) {
            if (fromServicePage) {
                navigate('/workshop/billing');
                return;
            }
            navigate('/workshop/plans')
        }
    }, [fromServicePage]);

    if (fetchingCarTypes || creatingCustomer) {
        return <Loader />
    }

    return (
        <>
            <div className='page'>
                <div className='card-container'>
                    <form>
                        {
                            formControls?.map((fc, index) => (
                                <div className='mb-3' key={index}>
                                    <div className='d-flex '>
                                        <Label title={fc?.name} />
                                        {
                                            fc?.required &&
                                            <sub>*</sub>
                                        }
                                    </div>
                                    {
                                        ['text', 'number', 'email'].includes(fc?.type) &&
                                        <Input {...fc} onChange={handleInput} />
                                    }
                                    {
                                        (fc.code === 'car_type' && fc.type === 'list') &&
                                        <Select {...fc} options={carTypesData?.data} onChange={handleInput} />
                                    }
                                    <Error title={errors[index]} />
                                </div>
                            ))
                        }
                        <div className='mt-3 d-flex justify-content-end'>
                            {
                                editCustomer === null ?
                                    <>
                                        <Button title={'Reset'} className='btn-secondary w-25 mx-4' action={resetForm} />
                                        <Button title={'Register'} className='btn-p w-25 ' action={handleRegister} />
                                    </>
                                    :
                                    <>
                                        <Button title={'Cancel'} className='btn-secondary w-25 mx-4' action={cancelAction} />
                                        <Button title={'Update'} className='btn-p w-25' action={handleRegister} />
                                    </>
                            }
                        </div>
                    </form>
                </div>
                <div className='mt-3 d-flex justify-content-center w-100'>
                    <Link to={'/workshop/existingUser'} className='poppins-semibold text-dark f-14 underline'>Are you registered user?</Link>
                </div>
            </div>
            {
                modalInfo?.show &&
                <InfoModal continueBtnText='Proceed to plan' {...modalInfo} icon={true} action={(type) => modalAction(type)} />
            }
        </>
    );
}

export default UserForm; 