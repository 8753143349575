import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import { Constant } from '../../../constants';
import { useHTTP } from '../../../hooks/useHTTP';
import Loader from '../../../components/Loader';
import InfoModal from '../../../components/InfoModal';
import { Edit } from '../../../assets/svg/Icons';
import Pagination from '../../../components/Pagination';
import { searchCustomerForm } from '../../../assets/json/forms';
import { Label } from '../../../components/Label';
import { Input } from '../../../components/Input';
import { Error } from '../../../components/Error';
import { useForm } from '../../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { getProceed, updateCustomerDetails } from '../../../store/features/planSlice';

function ExistingUser() {
    const { formControls, onChange, onSubmit, errors, reset } = useForm(searchCustomerForm);
    const { loading: searchingCustomer, callAPI: searchCustomer, data: searchResponse, error: errorForSearch } = useHTTP();
    const [modalInfo, setModalInfo] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fromServicePage = useSelector(getProceed);
    const [activePage, setActivePage] = useState(1);

    // Search Empty
    useEffect(() => {
        if (searchResponse?.status === Constant.success && !searchResponse?.data?.length) {
            setModalInfo({ show: true, type: Constant.error, msg: 'No data found.' });
            reset();
        }
    }, [searchResponse])

    // Search Input
    const handleInput = useCallback((e) => {
        const { name, id, value } = e?.target;
        onChange(value, id);
    }, [formControls])

    // Search Customer
    const handleSearch = useCallback(() => {
        if (onSubmit()) {
            searchCustomer(`customers?search=${formControls[0]?.value}`, Constant.GET)
        }
    }, [formControls]);

    // Cancel Action
    const cancel = () => {
        navigate('/workshop/plans')
    }

    // Pagination 
    const handlePageChange = useCallback((page) => {
        if (page?.url !== null) {
            const url = new URL(page?.url);
            const params = new URLSearchParams(url?.search);
            const pageNumber = params.get('page');
            if (activePage === pageNumber) return;
            setActivePage(pageNumber);
            searchCustomer(`customers?page=${pageNumber}&search=${formControls[0]?.value}`, Constant.GET)
        }
    }, [formControls]);

    // Preceed customer
    const onClickCustomer = useCallback((cus) => {
        dispatch(updateCustomerDetails(cus));
        if (fromServicePage) {
            navigate('/workshop/billing');
            return;
        }
        navigate('/workshop/plans');
    }, [fromServicePage]);

    // Modal 
    const modalAction = useCallback((type) => {
        if (type === Constant.close) {
            setModalInfo({})
        }
    }, []);

    if (searchingCustomer) {
        return <Loader />
    }

    return (
        <>
            <div className='page'>
                <div className='card-container'>
                    {
                        formControls?.map((fc, index) => (
                            <div key={index} className='mb-3'>
                                <Label title={fc.name} />
                                <Input {...fc} onChange={handleInput} />
                                <Error title={errors[index]} />
                            </div>
                        ))
                    }

                    <div className='mt-3 d-flex justify-content-end'>
                        <Button title={'Cancel'} className='btn-secondary w-25 mx-4' action={cancel} />
                        <Button title={'Search'} className='btn-p w-25' action={handleSearch} />
                    </div>
                </div>

                {
                    (searchResponse?.data?.length && searchResponse?.status === Constant.success) ?
                        <>
                            <div className='mt-5 w-100'>
                                <table className="table table-striped table-hover table-responsive existing-user-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Car Type</th>
                                            <th scope="col">Rego Number</th>
                                            {/* <th scope="col" className='text-center'>Proceed to plan</th> */}
                                            <th scope="col" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            searchResponse?.data?.map((cus, index) => {
                                                const { name, phone, email, regno, status, car_type } = cus;
                                                const { per_page, current_page } = searchResponse.pagination;

                                                return (
                                                    <tr key={index}>
                                                        <td>{((current_page - 1) * per_page + 1) + index}</td>
                                                        <td>{name}</td>
                                                        <td>{phone}</td>
                                                        <td>{email}</td>
                                                        <td>{car_type?.name}</td>
                                                        <td>{regno}</td>
                                                        <td className='text-center'>
                                                            <div className='d-flex justify-content-evenly'>
                                                                <Button className={'btn-p f-12 me-3'} title={'Proceed'} action={() => onClickCustomer(cus)} />

                                                                <Link to={{ pathname: '/workshop/editUser' }} state={JSON.stringify(cus)}>
                                                                    <Edit />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        {/* <td className='text-center'>
                                                            <Link to={{ pathname: '/workshop/editUser' }} state={JSON.stringify(cus)}>
                                                                <Edit />
                                                            </Link>
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                                <div className='d-flex justify-content-end'>
                                    <Pagination pagination={searchResponse?.pagination?.links} activePage={activePage} onClickPage={(page) => handlePageChange(page)} />
                                </div>
                            </div>

                        </>
                        :
                        <div className='mt-3'>
                            <Link to={'/workshop/newuser'} className='poppins-semibold text-dark f-14 underline'>Are you new user?</Link>
                        </div>
                }

            </div>
            {
                modalInfo?.show &&
                <InfoModal {...modalInfo} icon={true} action={(type) => modalAction(type)} />
            }
        </>
    );
}

export default ExistingUser; 