import { Outlet, Navigate } from 'react-router-dom'
import { Constant } from '../constants';
import { useSelector } from 'react-redux';
import { getAuth } from '../store/features/authSlice';

const ProtectedRouteWorkshop = () => {
    const { authendicated, role} = useSelector(getAuth);
    return (
        (authendicated && role.toLowerCase() === Constant.workshop.toLowerCase()) ? <Outlet /> :
            <Navigate to="/" />
    )
}

export default ProtectedRouteWorkshop;