import { useCallback, useEffect, useState } from "react";
import { TitleContainer } from "../../../components/TitleContainer"
import { useHTTP } from "../../../hooks/useHTTP";
import { Constant } from "../../../constants";
import Loader from "../../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { Delete, Edit } from "../../../assets/svg/Icons";
import Button from "../../../components/Button";
import InfoModal from "../../../components/InfoModal";

const Services = () => {
    const { loading: fetching, callAPI: getServices, data: services, error: errorForFetching } = useHTTP();
    const { loading: deleting, callAPI: deleteService, data: deleteResponse, error: errorForDelete } = useHTTP();
    const [modalInfo, setModalInfo] = useState({});
    const [selectedType, setSelectedType] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getServices('services', Constant.GET);
    }, []);

    useEffect(() => {
        if (deleteResponse?.status === Constant.success) {
            const options = {
                show: true,
                type: Constant.success,
                msg: deleteResponse?.message,
            };
            setModalInfo({ ...options });
            getServices('services', Constant.GET);
        }
    }, [deleteResponse]);

    const createNew = () => {
        navigate('/admin/serviceForm')
    }

    const deleteType = (id) => {
        const options = {
            show: true,
            type: Constant.error,
            msg: 'Are you sure to delete this item?',
            continueBtn: true,
            continueBtnText: 'Yes'
        };
        setModalInfo({ ...options });
        setSelectedType(id);
    }

    // Modal 
    const modalAction = useCallback((type) => {
        setModalInfo({});
        // if (type === Constant.close) {
        //     setModalInfo({})
        // }
        if (type === Constant.proceed) {
            deleteService(`services/delete/${selectedType}`, Constant.DELETE)
        }
    }, [selectedType]);

    if (fetching || deleting) {
        return <Loader />
    }
    return (
        <div className="">
            <TitleContainer title='Services' />
            <div className="w-100 ">
                <div className="text-end my-4">
                    <Button title={'Create New'} className={'btn-p'} action={() => createNew()} />
                </div>
                <div className="card-container w-100">
                    <table className="table table-striped table-hover table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Price</th>
                                <th scope="col" className='text-center'>Edit</th>
                                <th scope="col" className='text-center'>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                services?.data?.map((cType, index) => {
                                    const { name, price, status, uuid } = cType;
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{name}</td>
                                            <td>{price}</td>
                                            <td className='text-center'>
                                                <Link to={{ pathname: '/admin/serviceForm' }} state={JSON.stringify(cType)}>
                                                    <Edit />
                                                </Link>
                                            </td>
                                            <td className='text-center'>
                                                <button className="btn" onClick={() => deleteType(uuid)}>
                                                    <Delete />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                modalInfo?.show &&
                <InfoModal {...modalInfo} icon={true} action={(type) => modalAction(type)} />
            }
        </div>
    )
}

export default Services;