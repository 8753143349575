export const setSessionWorkshop = async (data) => {
    try {
        const sessionData = btoa(JSON.stringify(data));
        await localStorage.setItem('workshop', sessionData);
    } catch (e) {
        console.log('set session worksop error', e)
    }
}

export const getSessionWorkshop = () => {
    try {
        const sessionData = atob(localStorage.getItem('workshop'));
        return sessionData != null ? JSON.parse(sessionData) : null;
    } catch (e) {
        // error reading value
    }
}

export const clearSessionWorkshop = async () => {
    try {
        await localStorage.removeItem('workshop');
    } catch (e) {
        // remove error
    }
    console.log('Removed.')
}