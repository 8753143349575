import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

export const MultiSelect = ({
    options = [],
    multiple = true,
    closeMenuOnSelect = false,
    placeholder = '',
    noOptionsMessage = 'No Data Found',
    defaultValue = '',
    onChange
}) => {
    return (
        <Select
            closeMenuOnSelect={closeMenuOnSelect}
            components={animatedComponents}
            isMulti={multiple}
            options={options}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={defaultValue}
            onChange={onChange}
        />
    );
}