import { useCallback, useEffect, useMemo, useState } from "react";
import { TitleContainer } from "../../../components/TitleContainer"
import { useForm } from "../../../hooks/useForm";
import { useHTTP } from "../../../hooks/useHTTP";
import { Input } from "../../../components/Input";
import { Error } from "../../../components/Error";
import { serviceForm } from "../../../assets/json/forms";
import { Label } from "../../../components/Label";
import { Checkbox } from "../../../components/Checkbox";
import Button from "../../../components/Button";
import InfoModal from "../../../components/InfoModal";
import Loader from "../../../components/Loader";
import { Constant } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";

const ServiceForm = () => {
    const { formControls, setFormControls, onChange, onSubmit, errors, reset } = useForm(serviceForm);
    const { loading: isCreating, callAPI: createAPI, data: responseForCreate, error: errorForCreate } = useHTTP();
    const [modalInfo, setModalInfo] = useState({});
    const { state: updateService } = useLocation();
    const editService = useMemo(() => updateService !== null ? JSON.parse(updateService) : null, [updateService]);
    const btnName = editService === null ? 'Create' : 'Update';
    const navigate = useNavigate();

    useEffect(() => {
        if (editService === null) {
            reset();
        } else {
            const cpyFormControls = [...formControls];
            for (const key in editService) {
                cpyFormControls.find(fc => {
                    if (fc.code === key) {
                        fc.value = editService[key];
                    }
                })
            }
            setFormControls(cpyFormControls);
        }
    }, [editService]);

    // For Modal Message
    useEffect(() => {
        if (responseForCreate?.status === Constant.success) {
            let msg = { ...modalInfo };
            if (responseForCreate?.status === Constant.success) {
                msg = { show: true, type: Constant.success, msg: responseForCreate?.message };
            }
            setModalInfo(msg);
            reset();
        }

        if (errorForCreate !== null) {
            reset();
        }
    }, [errorForCreate, responseForCreate]);

    const handleInput = useCallback((e) => {
        const { name, id, value, type } = e?.target;
        onChange(value, id);
    }, [formControls]);

    const handelCheckbox = (e) => {
        const { name, id, value, type } = e?.target;
        const checked = value === 'true' ? false : true;
        onChange(checked, id);
    }

    const handleCreate = useCallback(() => {
        if (onSubmit()) {
            let payload = formControls.reduce((acc, currentElement) => {
                acc[currentElement.code] = currentElement.value;
                return acc;
            }, {});

            if (editService === null) {
                createAPI('services/create', Constant.POST, payload);
            }
            if (editService !== null) {
                const id = editService?.uuid;
                createAPI(`services/update/${id}`, Constant.POST, payload);
            }
        }
    }, [formControls]);

    // Modal 
    const modalAction = useCallback((type) => {
        if (type === Constant.close) {
            setModalInfo({})
        }

        if (editService !== null) {
            navigate('/admin/services')
        }
    }, []);

    if (isCreating) {
        return <Loader />
    }

    return (
        <div className="">
            <TitleContainer title='Create Service' />
            <div className="m-auto w-50">
                <div className="card-container w-100 my-4">
                    {
                        formControls?.map((fc, index) => (
                            <div key={index} className='mb-3'>
                                <Label title={fc?.name} />
                                {
                                    fc?.type === 'text' &&
                                    <Input {...fc} onChange={handleInput} />
                                }
                                {
                                    fc?.type === 'checkbox' &&
                                    <input {...fc} id={fc?.code} className="form-check-input pt-3" checked={fc?.value} onChange={handelCheckbox} />
                                }
                                <Error title={errors[index]} />
                            </div>
                        ))
                    }

                    <div className='mt-3'>
                        <Button title={btnName} className='btn-p' action={handleCreate} />
                    </div>
                </div>
            </div>
            {
                modalInfo?.show &&
                <InfoModal {...modalInfo} icon={true} action={(type) => modalAction(type)} />
            }
        </div>
    )
}

export default ServiceForm;