const admin = 'admin';
const employee = 'employee';
const workshop = 'workshop';
const API_URL = 'https://api.jscarwash.janjer.com.au/api/v1/';
const GET = 'GET';
const POST = "POST";
const DELETE = "DELETE";
const success = 'success';
const error = 'error';
const cancel = 'cancel';
const close = 'close';
const proceed = 'procesd';
const warning = 'warning';

export const Constant = {
    admin,
    employee,
    workshop,
    API_URL,
    GET,
    POST,
    DELETE,
    success,
    error,
    cancel,
    close,
    proceed,
    warning
}