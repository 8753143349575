import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const extraSlice = createSlice({
    name: 'extras',
    initialState: initialState,
    reducers: {
        updateExtras: (state, { payload }) => {
            const { isCheck, item } = payload;
            if (!state.includes(item)) {
                return [...state, item]
            } else {
                const findIndex = state.findIndex((ex) => ex === item);
                state.splice(findIndex, 1);
            }

        },
        removeExtra: (state, { payload }) => {
            const extras = state.filter(st => st !== payload);
            return extras;
        },

        clearExtras: (state, { payload }) => {

        }
    }
});

export const { updateExtras, removeExtra, clearExtras } = extraSlice.actions;
export const getExtras = (state) => state?.extras;
export default extraSlice.reducer;