import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    authendicated: false,
    access_token: null,
    expires_at: null,
    refresh_token: null,
    role: ''
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuth: (state, { payload }) => {
            return payload;
        },

        clearAuth: () => {
            return initialState;
        }
    }
});

export const { setAuth, clearAuth } = authSlice.actions;
export const getAuth = state => state?.auth;
export default authSlice.reducer;