import React, { useEffect, useMemo, useState } from 'react';
import './style.css';
import Button from '../../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { claerBilling, getBilling } from '../../../store/features/billingSlice';
import { clearPlanAndServices } from '../../../store/features/planSlice';
import { dateFormat } from '../../../utils/dateFormat';

const logo = require('../../../assets/images/logo.png');
function Invoice() {
    const { state: invoiceState } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { gst: GST } = useSelector(getBilling);
    const [billindData, setBillingData] = useState([]);
    const billingResponse = useMemo(() => invoiceState !== null ? JSON.parse(invoiceState?.invoiceData) : null, [invoiceState]);
    // console.log('billingResponse', billingResponse)
    useEffect(() => {
        if (billingResponse !== null) {
            let mapData = [];

            // const planData = billingResponse?.packages && { 'name': planFromStore?.name, 'price': 100 };
            const packageData = billingResponse?.packages?.map(pc => { return { 'name': pc?.name, 'price': pc?.inv_price, 'qty': pc?.qty, 'base_price': pc?.base_price } });
            const planData = billingResponse?.plans?.map(pl => { return { 'name': pl?.name, 'price': pl?.inv_price, 'qty': pl?.qty, 'base_price': pl?.base_price } });
            const servicesData = billingResponse?.services?.map(ser => { return { 'name': ser?.name, 'price': ser?.inv_price, 'qty': ser?.qty, 'base_price': ser?.base_price } });
            const extrasData = billingResponse?.others?.map(ext => { return { 'name': ext?.others, 'price': ext?.inv_price, 'qty': ext?.qty, 'base_price': ext?.base_price } });

            // mapData.push(planData);
            mapData.push([...packageData]);
            mapData.push([...planData]);
            mapData.push([...servicesData]);
            mapData.push([...extrasData]);

            const mappedData = mapData.flat();
            setBillingData(mappedData)
        }

    }, []);


    const close = () => {
        if (billingResponse?.from === 'reportPage') {
            navigate('/workshop/billReports');
            return;
        }
        dispatch(clearPlanAndServices());
        dispatch(claerBilling());
        navigate('/workshop/plans');
    }

    const printInvoice = () => {
        // close();
        window.print();
    }

    return (
        <div className='page invoice' >
            <div className='watermark'>
                {/* <h1>JS CAR WASH</h1> */}
                {/* <img src={logo} /> */}
            </div>
            <div className='btns'>
                <Button title={'Close'} className='btn-secondary' action={close} />
                <Button title={'Print'} className='btn-p mx-5' action={printInvoice} />
            </div>
            <div className='card-container mx-4 w-75'>
                <table className='w-100'>
                    <tbody>
                        <tr>
                            <td>
                                <img src={logo} alt='logo' className='logo' />
                            </td>

                            <td className='w-50 text-end'>
                                <h5 className='text-uppercase poppins-bold'>
                                    JS Car Detailing and cleaning group ltd
                                </h5>
                                <p className='poppins-light'>Portico Plaza</p>
                                <p className='poppins-light'>G1-G2, 17-19 Aurelia St</p>
                                <p className='poppins-light'>Toongabbie NSW 2146</p>
                                <p className='poppins-light'>+61 415998647</p>
                                <p className='poppins-light'>js.detailing30@gmail.com</p>
                                <p className='poppins-light'>ABN 18654577090</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h6 className='mt-3 poppins-semobold clr-text f-12'>Tax Invoice</h6>
                <div className=''>
                    <table className='w-100'>
                        <tbody>
                            <tr>
                                <td className='w-35'>
                                    <h6 className='label-title poppins-regular'>
                                        Invoice To
                                    </h6>
                                    <p className='poppins-light'>{billingResponse?.customers?.name}</p>
                                    <p className='poppins-light'>{billingResponse?.customers?.address}</p>
                                    <p className='poppins-light'>{billingResponse?.customers?.phone}</p>
                                    <p className='poppins-light'>{billingResponse?.customers?.email}</p>
                                    <p className='poppins-light'>{billingResponse?.customers?.abn_number ? `ABN ${billingResponse?.customers?.abn_number}` : ''}</p>
                                </td>

                                <td className='w-35'>
                                    <h6 className='label-title poppins-regular'>
                                        Ship To
                                    </h6>
                                    <p className='poppins-light'>{billingResponse?.customers?.name}</p>
                                    <p className='poppins-light'>{billingResponse?.customers?.address}</p>
                                    <p className='poppins-light'>{billingResponse?.customers?.phone}</p>
                                    <p className='poppins-light'>{billingResponse?.customers?.email}</p>
                                    <p className='poppins-light'>{billingResponse?.customers?.abn_number ? `ABN ${billingResponse?.customers?.abn_number}` : ''}</p>
                                </td>

                                <td className='w-30'>
                                    <table className=''>
                                        <tbody>
                                            <tr className='align-baseline'>
                                                <td>
                                                    <h6 className='label-title poppins-regular'>
                                                        Invoice
                                                    </h6>
                                                </td>
                                                <td className='px-3'>:</td>
                                                <td>
                                                    <p className='poppins-light'>{billingResponse?.invoice_id}</p>
                                                </td>
                                            </tr>

                                            <tr className='align-baseline'>
                                                <td>
                                                    <h6 className='label-title poppins-regular'>
                                                        Date
                                                    </h6>
                                                </td>
                                                <td className='px-3'>:</td>
                                                <td>
                                                    <p className='poppins-light'>{dateFormat(billingResponse?.invoice_date)} </p>
                                                </td>
                                            </tr>

                                            {/* <tr className='align-baseline'>
                                                <td>
                                                    <h6 className='label-title poppins-regular'>
                                                        Terms
                                                    </h6>
                                                </td>
                                                <td className='px-3'>:</td>
                                                <td>
                                                    <p className='poppins-light'>Net 15</p>
                                                </td>
                                            </tr> */}

                                            {/* <tr className='align-baseline'>
                                                <td>
                                                    <h6 className='label-title poppins-regular'>
                                                        Due Date
                                                    </h6>
                                                </td>
                                                <td className='px-3'>:</td>
                                                <td>
                                                    <p className='poppins-light'>02/04/2024</p>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='my-3'>
                    <table className="table ">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                {/* <th scope="col">Date</th> */}
                                <th scope="col">Package/ Plan/ Service</th>
                                {/* <th scope="col">Description</th> */}
                                {/* <th scope="col">GST</th> */}
                                <th scope="col">Rate</th>
                                <th scope="col" className='text-center'>Qty</th>
                                <th scope="col" className='text-end'>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                billindData?.map((item, index) => {
                                    const { name, price, qty, inv_price, base_price } = item;
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            {/* <td>07/06/2024</td> */}
                                            <td>{name}</td>
                                            <td>{Number(base_price).toFixed(2)}</td>
                                            {/* <td>F0577604, PO 202V39360</td> */}
                                            {/* <td>GST</td> */}
                                            <td className='text-center'>{qty}</td>
                                            <td className='text-end'>{Number(price).toFixed(2)}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>

                <table className='w-100'>
                    <tbody>
                        <tr>
                            <td className='w-50'>
                                &nbsp;
                            </td>
                            <td className='w-50'>
                                <table className='w-100'>
                                    <tbody>
                                        <tr>
                                            <td className='py-2'>
                                                <h6 className='label-title poppins-regular'>
                                                    Sub Total
                                                </h6>
                                            </td>
                                            <td className='text-end'>{Number(billingResponse?.sub_total).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td className='py-2'>
                                                <h6 className='label-title poppins-regular'>
                                                    GST
                                                </h6>
                                            </td>
                                            <td className='text-end'>{Number(billingResponse?.gst).toFixed(2)}</td>
                                        </tr>
                                        <tr className='total'>
                                            <td className='py-2'>
                                                <h6 className='label-title poppins-regular'>
                                                    Discount
                                                </h6>
                                            </td>
                                            <td className='text-end'>{billingResponse?.discount !== null ? Number(billingResponse?.discount).toFixed(2) : 0}</td>
                                        </tr>
                                        <tr>
                                            <td className='py-2'>
                                                <h6 className='label-title poppins-semibold text-dark'>
                                                    Total
                                                </h6>
                                            </td>
                                            <td className='text-end poppins-semibold text-dark'>A$ {Number(billingResponse?.total).toFixed(2)}</td>
                                        </tr>

                                        {/* <tr>
                                            <td className='py-3'>
                                                <h6 className='label-title poppins-regular'>
                                                    Balance Due
                                                </h6>
                                            </td>
                                            <td className='text-end poppins-bold'>A$.00</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {
                    // billingResponse?.notes &&
                    <>
                        <h6 className='mt-3 poppins-semobold clr-text f-12 text-uppercase'>Notes</h6>
                        <div className='footer'>
                            <p>Rego Number: {billingResponse?.customers?.regno}</p>
                            <p>Car Type: {billingResponse?.customers?.car_type?.name}</p>
                            <p>{billingResponse?.notes}</p>
                        </div>
                    </>
                }

                <div className=''>
                    <h6 className='mt-3 poppins-semobold clr-text f-12 text-uppercase'>BAS Summary</h6>
                    <table className="table text-end m-0">
                        <thead>
                            <tr>
                                <th scope="col">&nbsp;</th>
                                <th scope="col">Rate</th>
                                <th scope="col">GST</th>
                                <th scope="col">NET</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>&nbsp;</td>
                                <td>GST &copy; {GST}%</td>
                                <td>{Number(billingResponse?.gst).toFixed(2)}</td>
                                <td>{Number(billingResponse?.sub_total).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='footer'>
                        <p>BSB Number: 062534</p>
                        <p>Account Number: 10709915</p>
                        <p>Account Name: JS Car Detailing and Cleaning Group PTY LTD</p>
                        <p>Bank: Commonwealth</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Invoice; 
