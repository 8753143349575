import axios from "axios";
import { Constant } from "../constants";
import { useCallback, useEffect, useState } from "react";
import { getSessionWorkshop, setSessionWorkshop } from "../utils/session";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, setAuth } from "../store/features/authSlice";

const initialState = {
    loading: false,
    data: [],
    success: false,
    error: null
}
export const useHTTP = () => {
    const [state, setState] = useState(initialState);
    const dispath = useDispatch();

    const getToken = () => {
        const tokenFromSession = atob(localStorage.getItem('workshop'));
        const parseData = tokenFromSession != null ? JSON.parse(tokenFromSession) : null;
        return parseData;
    }
    const callAPI = async (url, method, data = null, token = null, header = {}) => {
        setState((prev) => ({ ...prev, loading: true, success: false }));
        const at = token !== null ? token : getToken()?.access_token;
        // console.log('call api', at);
        axios({
            method: method,
            url: `${Constant.API_URL}${url}`,
            data: data,
            headers: { 'Authorization': `Bearer ${at}`, ...header }
        })
            .then(res => {
                setState((prev) => ({ ...prev, loading: false, success: true, data: res?.data, error: null }));
            })
            .catch(err => {
                console.log('Response Err', err);
                switch (err?.response?.status) {
                    case 401:
                        if (err?.response?.data?.sub_status === 'token_expired') {
                            getNewToken(url, method, data);
                        }
                        return;
                        default:
                        setState((prev) => ({ ...prev, loading: false, success: false, data: [], error: err?.response?.data }));
                        return;
                }
            });
    };

    const getNewToken = async (url, method, postData) => {
        const payload = {
            'grant-type': 'refresh-token',
            'refresh-token': getToken()?.refresh_token
        }
        axios.post(`${Constant.API_URL}token`, payload)
            .then(async res => {
                const data = {
                    authendicated: true,
                    ...res?.data?.data
                };
                dispath(setAuth(data));
                await setSessionWorkshop(data);
                callAPI(url, method, postData, res?.data?.data?.access_token);
            })
            .catch(err => console.log('token err', err))

    }

    return { ...state, callAPI }
}