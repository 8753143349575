const PackageCard = ({ plan, selectedCarType }) => {
    const { name, plans = [], services = [], packages = [], prices = [] } = plan;
    return (
        <div className='price-card-wrap c-pointer'>
            <div className='price-title p1'>
                <h6 className='poppins-semibold m-0 text-center text-white'>{name}</h6>
            </div>

            {
                prices?.map((pr, index) => {
                    return (
                        pr?.uuid === selectedCarType &&
                        <div key={index} className='plan-details my-3'>
                            <ul className='m-0 p-0 my-4 text-center price-wrap'>
                                <li><h1 className='poppins-bold m-0 text-white'><span className='poppins-semibold'>$</span>{pr?.price}</h1></li>
                            </ul>
                        </div>
                    )
                })
            }

            <ul className='m-0 p-0 my-4'>
                {
                    plans?.map((plan, index) => (
                        <li key={index} className='my-3'>
                            <h6 className='poppins-medium d-flex'>
                                <span className='text-white'>&#10003;</span>
                                <span className='text-white mx-2'>Include {plan?.name}</span>
                            </h6>
                        </li>
                    ))
                }
                {
                    packages?.map((pck, index) => (
                        <li key={index} className='my-3'>
                            <h6 className='poppins-medium d-flex'>
                                <span className='text-white'>&#10003;</span>
                                <span className='text-white mx-2'> include {pck?.name}</span>
                            </h6>
                        </li>
                    ))
                }
                {
                    services?.map((service, index) => (
                        <li key={index} className='my-3'>
                            <h6 className='poppins-medium d-flex'>
                                <span className='text-white'>&#10003;</span>
                                <span className='text-white mx-2'>{service?.name}</span>
                            </h6>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default PackageCard;