import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { Checkbox } from "../../../components/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { getExtras, updateExtras } from "../../../store/features/extrasSlice";

const Extras = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const extrasData = useSelector(getExtras);
    
    const navigateToBilling = () => {
        navigate('/workshop/billing')
    }

    const onSelectExtra = (e, item) => {
        const isCheck = e?.target?.checked;
        const payload = { isCheck, item };
        dispatch(updateExtras(payload))
    }
    return (
        <div className='page'>
            <div className="text-end w-100 mb-3">
                <Button title={'Go to Billing Page'} className={'btn-p'} action={() => navigateToBilling()} />
            </div>
            <div className='card-container w-100'>
                <div className="mb-3">
                    <input type="text" className="form-control w-25 w-xs-100 w-sm-75 w-md-50" placeholder="Search extras" />
                </div>
                <div className="row">
                    {
                        ['Bugs & Tar Removal', 'Seats & Mats Steam', 'Vacuum Process (Pet hair & sand)', 'Sticker Removal', 'Carpet Steam Clean', 'Leather Clean', 'Buff Polish', 'Headlight Polish']
                            .map((item, index) => (
                                <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 my-2">
                                    <div style={styles?.extrasInput}>
                                        <Checkbox label={item} label2={120} checked={extrasData.includes(item)} onChange={(e) => onSelectExtra(e, item)} />
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Extras;

const styles = {
    extrasInput: {
        background: 'var(--gray)',
        height: '100%',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px'
    }
}