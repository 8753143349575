export const checkABN = (abn) => {
    // Check if ABN is a string of 11 digits
    const abnRegex = /^\d{11}$/;
    if (!abnRegex.test(abn)) {
        return false;
    }

    // Convert the ABN string into an array of integers
    const abnDigits = abn.split('').map(Number);

    // Subtract 1 from the first digit
    abnDigits[0] -= 1;

    // Define the weights for the ABN validation
    const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

    // Calculate the weighted sum of the digits
    const weightedSum = abnDigits.reduce((sum, digit, index) => sum + digit * weights[index], 0);

    // Check if the weighted sum is divisible by 89
    return weightedSum % 89 === 0;
}