import React, { useCallback, useEffect, useState } from 'react';
import './login.css';
import logo from '../../assets/images/logo.png';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useHTTP } from '../../hooks/useHTTP';
import { Constant } from '../../constants';
import InfoModal from '../../components/InfoModal';
import Loader from '../../components/Loader';
import { setSessionWorkshop } from '../../utils/session';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, setAuth } from '../../store/features/authSlice';
import { useForm } from '../../hooks/useForm';
import { loginForm } from '../../assets/json/forms';
import { Input } from '../../components/Input';
import { Error } from '../../components/Error';

function Login() {
    const { formControls, onChange, onSubmit, errors, reset } = useForm(loginForm);
    const { loading: isLogin, callAPI: loginAPI, data: responseForLogin, error: errorForLogin } = useHTTP();
    const [modalInfo, setModalInfo] = useState({});
    const navigate = useNavigate();
    const dispath = useDispatch();
    const { authendicated, role } = useSelector(getAuth);
    useEffect(() => {
        if (authendicated && role === Constant.workshop) {
            navigate('/workshop/plans');
        }
        if (authendicated && role === Constant.admin) {
            navigate('/admin/home');
        }
    }, [authendicated]);

    useEffect(() => {
        let msg = { ...modalInfo };
        if (errorForLogin?.status === Constant.error) {
            msg = { show: true, type: Constant.error, msg: errorForLogin?.message };
        }
        if (responseForLogin?.status === Constant.success) {
            const loginData = {
                authendicated: true,
                ...responseForLogin?.data
            };

            dispath(setAuth(loginData))
            setSessionWorkshop(loginData);
            navigate('/workshop/plans');
        }
        setModalInfo(msg);
        reset();
    }, [responseForLogin, errorForLogin])

    const handleInput = useCallback((e) => {
        const { name, id, value } = e?.target;
        onChange(value, id);
    }, [formControls]);

    const handleLogin = useCallback(() => {
        if (onSubmit()) {
            let payload = formControls.reduce((acc, currentElement) => {
                acc[currentElement.code] = currentElement.value;
                return acc;
            }, {});

            const payloadData = {
                ...payload,
                'grant-type': 'password',
            }
            loginAPI('token', Constant.POST, payloadData, 'no-token');
        }
    }, [formControls]);

    // Modal 
    const modalAction = useCallback((type) => {
        if (type === Constant.close) {
            setModalInfo({})
        }
    }, []);

    if (isLogin) {
        return <Loader />
    }

    return (
        <>
            <div className='login-page'>
                <div className='login-container d-flex align-items-center justify-content-center'>
                    <div className='line line-1'></div>
                    <div className='line line-2'></div>

                    <div className='login-wrap'>
                        <img src={logo} className='logo mb-3' alt='logo' />
                        <form>
                            {
                                formControls?.map((fc, index) => (
                                    <div key={index} className='mb-3'>
                                        <Input {...fc} onChange={handleInput} />
                                        <Error title={errors[index]} />
                                    </div>
                                ))
                            }
                            <div className='mt-3'>
                                <Button title={'Login'} className='btn-p w-100' action={handleLogin} />
                            </div>

                        </form>
                    </div>

                </div>
            </div>
            {
                modalInfo?.show &&
                <InfoModal {...modalInfo} icon={true} action={(type) => modalAction(type)} />
            }
        </>
    );
}

export default Login; 