import { configureStore } from "@reduxjs/toolkit";
import extrasSlice from "./features/extrasSlice";
import billingSlice from "./features/billingSlice";
import authSlice from "./features/authSlice";
import planSlice from "./features/planSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        extras: extrasSlice,
        billing: billingSlice,
        plans: planSlice
    }
})