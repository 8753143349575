import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    plan: {},
    services: [],
    carType: null,
    extras: [],
    customerDetails: null,
    proceedFrom: false,
    selectedType: ''
};

const planSlice = createSlice({
    name: 'plan',
    initialState: initialState,
    reducers: {
        updatePlan: (state, { payload }) => {
            return { ...state, plan: payload };
        },
        updateService: (state, { payload }) => {
            const { services } = state;
            const alreadyExists = services.find(ser => ser?.uuid === payload?.uuid);
            if (alreadyExists) {
                const removeSelected = services.filter(s => s?.uuid !== payload?.uuid);
                return { ...state, services: removeSelected };
            } else {
                return { ...state, services: [...state.services, payload] };
            }
        },
        updateServicePrice: (state, { payload }) => {
            const { services } = state;
            const updatedServices = services?.map(ser => ser.uuid === payload?.uuid ? { ...ser, 'servicePrice': payload?.servicePrice } : ser);
            return { ...state, services: updatedServices };
        },
        updateServicePriceTotal: (state, { payload }) => {
            const { services } = state;
            const updatedServices = services?.map(ser => ser.uuid === payload?.uuid ? { ...ser, 'servicePriceTotal': payload?.servicePriceTotal } : ser);
            return { ...state, services: updatedServices };
        },
        updateServiceQty: (state, { payload }) => {
            const { services } = state;
            const updatedServices = services?.map(ser => ser.uuid === payload?.uuid ? { ...ser, 'qty': payload?.qty } : ser);
            return { ...state, services: updatedServices };
        },
        updateCarType: (state, { payload }) => {
            return { ...state, carType: payload };
        },
        updateExtras: (state, { payload }) => {
            return { ...state, extras: [...state.extras, payload] };
        },
        removeExtra: (state, { payload }) => {
            const { extras } = state;
            const updateExtra = extras.filter(s => s?.id !== payload?.id);
            return { ...state, extras: updateExtra };
        },
        updateExtraName: (state, { payload }) => {
            const { extras } = state;
            const updateExtra = extras?.map(ext => ext.id === payload?.id ? { ...ext, 'name': payload?.name } : ext);
            return { ...state, extras: updateExtra };
        },
        updateExtraPrice: (state, { payload }) => {
            const { extras } = state;
            const updateExtra = extras?.map(ext => ext.id === payload?.id ? { ...ext, 'price': payload?.price } : ext);
            return { ...state, extras: updateExtra };
        },
        updateExtrasQty: (state, { payload }) => {
            const { extras } = state;
            const updateExtra = extras?.map(ext => ext.id === payload?.id ? { ...ext, 'qty': payload?.qty } : ext);
            return { ...state, extras: updateExtra };
        },
        updateExtraPriceTotal: (state, { payload }) => {
            const { extras } = state;
            const updateExtra = extras?.map(ext => ext.id === payload?.id ? { ...ext, 'totalPrice': payload?.totalPrice } : ext);
            return { ...state, extras: updateExtra };
        },
        updateProceedFrom: (state, { payload }) => {
            return { ...state, proceedFrom: payload }
        },
        updateCustomerDetails: (state, { payload }) => {
            return { ...state, customerDetails: payload };
        },
        updateSelectedType: (state, { payload }) => {
            return { ...state, selectedType: payload };
        },
        clearPlan: (state) => {
            return { ...state, plan: {} };
        },
        clearServices: (state) => {
            return { ...state, services: [] };
        },
        clearPlanAndServices: () => {
            return initialState;
        }
    }
})


export const { updatePlan, updateService, updateServicePrice, updateServicePriceTotal, updateServiceQty,updateCarType, updateExtras, removeExtra, updateExtraName, updateExtraPrice,updateExtrasQty, updateExtraPriceTotal, updateProceedFrom, updateCustomerDetails, updateSelectedType,  clearPlan, clearServices, clearPlanAndServices } = planSlice.actions;
export const getSelectedPlans = state => state?.plans;
export const getProceed = state => state?.plans?.proceedFrom;
export const getSelectedType = state => state?.plans?.selectedType;
export const getCustomerDetails = state => state?.plans?.customerDetails;
export default planSlice.reducer;
