export const Success = () => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 50 50" xmlSpace="preserve">
            <circle style={{ 'fill': '#25AE88' }} cx="25" cy="25" r="25" />
            <polyline style={{ 'fill': 'none', 'stroke': '#FFFFFF', 'strokeWidth': 2, 'strokeLinecap': 'round', 'strokeLinejoin': 'round', 'strokeMiterlimit': 10 }} points="
	38,15 22,33 12,25 "/>
        </svg>
    )
}

export const Error = () => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 50 50" xmlSpace="preserve">
            <circle style={{ 'fill': '#D75A4A' }} cx="25" cy="25" r="25" />
            <polyline style={{ 'fill': 'none', 'stroke': '#FFFFFF', 'strokeWidth': 2, 'strokeLinecap': 'round', 'strokeMiterlimit': 10 }} points="16,34 25,25 34,16" />
            <polyline style={{ 'fill': 'none', 'stroke': '#FFFFFF', 'strokeWidth': 2, 'strokeLinecap': 'round', 'strokeMiterlimit': 10 }} points="16,16 25,25 34,34" />
        </svg>
    )
}

export const Edit = () => {
    return (
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z" fill="#000000" />
        </svg>
    )
}

export const Delete = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="20px" height="20px">
            <g fill="#f54a4a" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ "mixBlendMode": "normal" }}>
                <g transform="scale(8.53333,8.53333)"><path d="M14.98438,2.48633c-0.55152,0.00862 -0.99193,0.46214 -0.98437,1.01367v0.5h-5.5c-0.26757,-0.00363 -0.52543,0.10012 -0.71593,0.28805c-0.1905,0.18793 -0.29774,0.44436 -0.29774,0.71195h-1.48633c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h18c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587h-1.48633c0,-0.26759 -0.10724,-0.52403 -0.29774,-0.71195c-0.1905,-0.18793 -0.44836,-0.29168 -0.71593,-0.28805h-5.5v-0.5c0.0037,-0.2703 -0.10218,-0.53059 -0.29351,-0.72155c-0.19133,-0.19097 -0.45182,-0.29634 -0.72212,-0.29212zM6,9l1.79297,15.23438c0.118,1.007 0.97037,1.76563 1.98438,1.76563h10.44531c1.014,0 1.86538,-0.75862 1.98438,-1.76562l1.79297,-15.23437z">
                </path>
                </g>
            </g>
        </svg>
    )
}

export const Warning = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve">
            <polygon style={{ "fill": "#FFA418" }} points="0,477.703 256,477.703 289.391,256 256,34.297 " />
            <polygon style={{ "fill": "#FF8A1E" }} points="256,34.297 256,477.703 512,477.703 " />
            <g>
                <circle style={{ "fill": "#324860" }} cx="256" cy="405.359" r="16.696" />
                <rect x="239.304" y="177.185" style={{ "fill": "#324860" }} width="33.391" height="178.087" />
            </g>
        </svg>
    )
}

export const ViewIco = () => {
    return (
        <svg fill="#000000" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 80.794 80.794" xmlSpace="preserve">
            <g>
                <g>
                    <path d="M79.351,38.549c-0.706-0.903-17.529-22.119-38.953-22.119c-21.426,0-38.249,21.216-38.955,22.119L0,40.396l1.443,1.847
			c0.706,0.903,17.529,22.12,38.955,22.12c21.424,0,38.247-21.217,38.953-22.12l1.443-1.847L79.351,38.549z M40.398,58.364
			c-15.068,0-28.22-13.046-32.643-17.967c4.425-4.922,17.576-17.966,32.643-17.966c15.066,0,28.218,13.045,32.642,17.966
			C68.614,45.319,55.463,58.364,40.398,58.364z"/>
                    <path d="M40.397,23.983c-9.052,0-16.416,7.363-16.416,16.414c0,9.053,7.364,16.417,16.416,16.417s16.416-7.364,16.416-16.417
			C56.813,31.346,49.449,23.983,40.397,23.983z M40.397,50.813c-5.744,0-10.416-4.673-10.416-10.417
			c0-5.742,4.672-10.414,10.416-10.414c5.743,0,10.416,4.672,10.416,10.414C50.813,46.14,46.14,50.813,40.397,50.813z"/>
                </g>
            </g>
        </svg>
    )
}