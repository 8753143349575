import { monthName } from "../assets/json/monthName";

export const dateFormat = (arg) => {
    const date = new Date(arg);

    // Extract the day, month, and year
    const day = date.getDate();
    const month = monthName[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as "26 July 2024"
    const formattedDate = `${day} ${month.substring(0, 3)} ${year}`;
    return formattedDate;

}