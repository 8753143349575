const Loader = () => {
    const loader = require('../assets/images/loader.gif');
    return (
        <div style={styles.loaderWrap}>
            <img src={loader} alt='loader' style={styles?.loader} />
        </div>
    )
}
export default Loader;

const styles = {
    loaderWrap: {
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#00000090',
        height: '100%',
        width: '100%',
        zIndex: 9,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    loader: {
        height: '4rem',
        width: '4rem'
    }
}