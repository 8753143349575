import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../contexts/Auth';

const logo = require('../assets/images/logo.png');
const Header = () => {
    const { logout } = useContext(AuthContext);
    return (
        <div className='nav-header'>
            <nav className="navbar navbar-expand-sm bg-body-white">
                <div className="container">
                    <NavLink to={'plans'} className='navbar-brand' onClick={() => window.location.reload()}>
                        <img src={logo} alt='logo' />
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                            <li className="nav-item">
                                <NavLink to={'newUser'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                    New User
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={'existingUser'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Existing User</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={'plans'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Services</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={'billReports'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Reports</NavLink>
                            </li>

                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown link
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li> */}
                        </ul>

                        <ul>
                            <li className="nav-item">
                                <Link className='nav-link m-0' onClick={logout}>Logout</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;