export const Select = ({
    name = '',
    code = '',
    value = '',
    placeholder = '',
    classname = '',
    options = [],
    onChange
}) => {

    return (
        <select name={name} id={code} className="form-select" value={value} aria-label="select" onChange={onChange}>
            <option defaultValue={'-'}>-</option>
            {
                options?.map((item, index) => {
                    const { uuid, name } = item;
                    return (
                        <option value={uuid} key={uuid}>{name}</option>
                    )
                })
            }
        </select>
    )
}