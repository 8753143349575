import { useState } from "react"
import { ausPhoneNumberRegex, emailRegex, phoneNumberRegex } from "../utils/regex";
import { checkABN } from "../utils/checkABN";

export const useForm = (formGroup = []) => {
    const [formControls, setFormControls] = useState(formGroup);
    const [errors, setErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);

    const onChange = (val, code) => {
        let updatedFormValue = formControls.map((form) => form.code === code ? { ...form, value: val } : form);
        setFormControls(updatedFormValue);
    }

    const validation = (form) => {
        let error = {};
        form.forEach((formData, index) => {
            if (formData.type === 'checkbox' || formData?.value === null || formData?.value === undefined) { return }
            if (formData.required && !formData.value.trim()) {
                error[index] = `Please fill ${formData.name}`;
            }

            if (formData.code === 'phone' && formData.value.trim()) {
                const isValidPhone = ausPhoneNumberRegex.test(formData?.value);
                if (!isValidPhone) {
                    error[index] = 'Please enter valid Phone number';
                }
            }
            if (formData.type === 'email' && formData.value.trim()) {
                const isValidMail = emailRegex.test(formData?.value);
                if (!isValidMail) {
                    error[index] = 'Please enter valid Email';
                }
            }

            if (formData.code === 'abn_number' && formData.value.trim()) {
                if (!checkABN(formData?.value)) {
                    error[index] = 'Please enter valid ABN Number';
                }
            }
        });
        return error;
    }

    const onSubmit = () => {
        let status = false;
        const checkValidation = validation(formControls);
        if (Object.keys(checkValidation).length === 0) {
            setFormSubmitted(true);
            setErrors({});
            status = true;
        } else {
            setErrors(checkValidation);
            status = false;
        }
        return status;
    }

    const reset = () => {
        const cpyFormControls = [...formControls];
        cpyFormControls.map(fc => {
            if (['text', 'multiline', 'number', 'list', 'email', 'password'].includes(fc.type)) {
                fc.value = '';
            }

            if (['file'].includes(fc.type)) {
                fc.value = null;
            }

            // if (['checkbox'].includes(fc.type)) {
            //     fc.value = false;
            // }
        });
        setFormControls(cpyFormControls);
        setErrors({});
    }

    return { formControls, setFormControls, errors, formSubmitted, onChange, onSubmit, reset };
}