import React from 'react';
import { TitleContainer } from '../../components/TitleContainer';

function Home() {
    return (
        <div>
            <TitleContainer title='Dashboard'/>
        </div>
    );
}

export default Home; 