import './App.css';
import '../src/assets/css/responsive.css';
import AppRoutes from './routes/AppRoutes';

function App() {
  return (
    <div className="App">
        <AppRoutes />
    </div>
  );
}

export default App;
