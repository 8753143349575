const Pagination = ({ pagination, activePage = 1, onClickPage }) => {
    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
                {
                    pagination?.map((item, index) => {
                        const pageName = index === 0 ? 'Previous' : index === pagination?.length - 1 ? 'Next' : item?.label;
                        return (
                        <li key={index} className={`page-item ${(Number(activePage) === index  )&& 'active'}`} aria-current="page">
                            <button className="page-link" onClick={() => onClickPage(item)}> {pageName}</button>
                        </li>
                    )})
                }
            </ul>
        </nav>
    )
}

export default Pagination;
